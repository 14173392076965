@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.employees {
  &__add {
    @include mix.flexArea(row, center, center);
    width: 50%;
    padding: 2rem;
    border: 1px grey solid;
    gap: 3rem;

    &__passwordgen {
      direction: ltr;
    }

    .p-inputtext {
      opacity: 1;
      font-size: medium;
      direction: rtl;
    }
  }

  &__upload {
    direction: ltr;
  }

  .p-sortable-column-icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  .p-column-title {
    font-size: large;
  }

  td {
    font-size: large;
  }
}
