@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.statistics {
  direction: ltr;
  display: flex;
  justify-content: center;
  width: 100%;

  &__container {
    @include mix.flexArea(row, center, center);
    width: 100%;
    padding: 0 15%;
    gap: 2rem;
  }

  .p-tabview {
    width: 100%;
  }

  .p-tabview-nav {
    justify-content: center;
  }

  .p-datatable {
    direction: rtl;
  }
  .p-column-header-content {
    display: flex;
    justify-content: space-between;
  }
  .p-column-filter-menu {
    margin: 0;
  }
}
