@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.nav {
  direction: rtl;
  padding: vars.$spacing-xx-atomic vars.$spacing-atomic;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f6f9ff;
  z-index: 2;

  &__logo {
  }

  .p-tabmenu,
  .p-tabmenu-nav,
  .p-tabmenuitem,
  .p-menuitem-link {
    background: transparent !important;
  }

  &__tabs {
  }

  &__icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    gap: 1rem;

    &__btns {
      @include mix.flexArea(row, center, center);
    }

    &__btns button {
      width: 44px;
      height: 44px;
      flex-shrink: 0;
      border: transparent;
      border-radius: 27px;
      margin-left: 10px;
    }

    &__btns button:hover {
      cursor: pointer;
      transition: all 400ms ease;
    }
  }
}
