@mixin text($color, $size, $weight, $lineHeight) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
  line-height: $lineHeight;
}

@mixin area($display, $position, $width, $height, $padding, $margin, $backgroundColor) {
  display: $display;
  position: $position;
  width: $width;
  height: $height;
  padding: $padding;
  margin: $margin;
  background-color: $backgroundColor;
}

@mixin borderedArea($borderThickness, $borderColor, $borderRadius) {
  border: $borderThickness solid $borderColor;
  border-radius: $borderRadius;
}

@mixin flexArea($direction, $alignItems, $justifyContent) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin gridArea($direction, $gap, $autoColumns, $templateRows, $templateColumns) {
  display: grid;
  grid-gap: $gap;
  grid-auto-flow: $direction;
  grid-auto-columns: $autoColumns;
  grid-template-rows: minmax($templateRows, 1fr);
  grid-template-columns: $templateColumns;
}

@mixin divider($height: 1px, $color, $width) {
  border-top: $height solid $color;
  width: $width;
}

@mixin button($bg-color, $fg-color, $border-radius, $border-thickness, $border-color, $hover-bg-color) {
  background-color: $bg-color;
  color: $fg-color;
  border-radius: $border-radius;
  border: $border-thickness solid $border-color;
  cursor: pointer;
  transition: 300ms all ease-in-out;

  &:hover {
    background-color: $hover-bg-color;
  }
}

@mixin discrCard($width){
    display: flex;
    flex-direction: row;
    align-items:center ;
    justify-content: center;
    gap: 11.25em;
    margin: 1rem 0 1rem;
    width: $width;
    height: 2.4375rem;
    background: var(--primary-with-20-opacity, rgba(162, 251, 93, 0.20));
    font-size: 1.125em;
    font-weight: 600;
    color: #333333;
    line-height: normal;;
}
