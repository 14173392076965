@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.login {
  height: 100vh;

  @include mix.flexArea(row, center, center);

  .login__form {
    @include mix.flexArea(column, center, center);
    direction: rtl;

    &__subheader {
      text-align: start;
      @include mix.text(vars.$color-black, vars.$text-size-micro-x, unset, normal);
      font-family: 'IBMPlex-SemiBold';
      margin-bottom: vars.$spacing-xx-atomic;
    }

    &__header {
      text-align: start;
      @include mix.text(vars.$color-black, 42px, unset, normal);
      font-family: 'IBMPlex-Bold';
      margin-bottom: vars.$spacing-x-atomic;
    }

    &__input {
      padding-right: 0.5rem;
      margin-bottom: 2rem;
      width: 387px;
      height: 50px;
      top: 469px;
      left: 228px;
      border-radius: 5px;
      border: 1px solid black;
    }

    &__btn {
      @include mix.button(vars.$button-color, black, vars.$spacing-atomic-xx, 1px, black, vars.$button-color-hover);
      @include mix.text(black, vars.$text-size-micro, 600, normal);
      width: 327px;
      height: 50px;
      margin: 0.5rem 2rem 0 0;
    }

    &__link {
      line-height: 5rem;
      text-align: center;
      :hover {
        text-decoration: underline;
      }
    }

    &__error {
      @include mix.text(red, vars.$text-size-x-micro, unset, normal);
    }
  }
}
