@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.settings {
	padding: 3rem;
  direction: ltr;
  display: flex;
	flex-direction: column;
  align-items: center;
  gap: 2rem;
}
