@use '../../style/variables' as vars;
@use '../../style/mixins' as mix;

.add-employee {
  @include mix.flexArea(row, center, center);

  &__passwordgen {
    direction: ltr;
  }

  &__upload {
    direction: ltr;
  }

  .p-inputtext {
    opacity: 1;
    font-size: medium;
    direction: rtl;
  }
}
